import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useEventContext } from '../../../contexts/EventProvider'
import { Container, Box, Text, Heading, VStack, Button, Image, useDisclosure, SimpleGrid } from '@chakra-ui/react'
import Theatre from './Theatre'
import SendPrivateSelector from './selectors/SendPrivateSelector'
import SendGeneralSelector from './selectors/SendGeneralSelector'

import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { useThankYouVideo } from '../../../hooks/useThankYouVideo'
import usePublishedMedia from '../../../hooks/usePublishedMedia'
import { useEventRecipients } from '../../../hooks/useRecipients'

import ModalActivateEventPage from '../../organisms/ModalActivateEventPage'
import FooterBase from '../../molecules/FooterBase'
import KeepsakeCards from '../Keepsake/atoms/KeepsakeCards'
import { history } from '../../../../history'
import ThankYouSelector from '../../molecules/ThankYouSelector'
import useScrollToHash from '../../../hooks/useScrollToHash'
import { useLocation } from 'react-router-dom'
import Divider from '../../atoms/Divider'
import { SmartInviteSelector } from '../SendToGeneral/selectors/SmartInviteSelector'
import { useAuthContext } from '../../../contexts/AuthProvider'
import { useEventOccasion } from '../../../hooks/useEventOccasion'

const Published = () => {
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const handlers = useEventHandlersV2(event.uuid)
	const thankYouVideo = useThankYouVideo()
	const publishedMedia = usePublishedMedia()
	const { hasSubscription } = useAuthContext()
	const { type } = useEventOccasion()
	const isMemorial = type == 'memorial'
	const [showKeepsake, setShowKeepsake] = useState(false)
	useEffect(() => {
		if (publishedMedia?.showKeepsakeProducts) {
			setShowKeepsake(true)
		}
	}, [publishedMedia])

	/** Activate Event Page Modal */
	// const [modalOpen, setModalOpen] = useState(false)
	const modalActivate = useDisclosure()
	// TODO : REVIEW WHAT IS A REMAKE
	const isRemake = true

	const handleClickRecipient = () => history.push(handlers.share_recipient)
	const handleClickGeneral = () => history.push(handlers.share_general)
	const handleClickInviteList = () => history.push(handlers.invitation_list)

	const location = useLocation()
	useScrollToHash({ location })

	return (
		<>
			<Helmet>
				<title>VidDay for {recipients.formatted}</title>
			</Helmet>

			<ModalActivateEventPage event={event} isOpen={modalActivate.isOpen} onClose={modalActivate.onClose} />

			<Container maxW="container.lg" pb="2rem">
				<Theatre />

				<SimpleGrid
					columns={
						thankYouVideo
							? isMemorial
								? [1, 1, 1, 2]
								: [1, 1, 1, 3]
							: isMemorial
							? [1, 1, 1, 1]
							: [1, 1, 1, 2]
					}
					spacing="1rem"
					mb="1rem">
					{!isMemorial && <SendPrivateSelector recipients={recipients} onClick={handleClickRecipient} />}

					<SendGeneralSelector onClick={handleClickGeneral} />

					{thankYouVideo && <ThankYouSelector video={thankYouVideo} role={'creator'} event={event} />}
				</SimpleGrid>

				<Divider text="More" my="2rem" variant="dashed" />

				<SmartInviteSelector onClick={handleClickInviteList} mb="1rem" />

				{showKeepsake && <KeepsakeCards />}

				<Box pt="2rem">
					<VStack spacing="1rem">
						<Image src="/assets/images/icons/edit_video.svg" boxSize="50px" alt="Edit Video" />
						<Box textAlign="center">
							<Heading as="h4" size="md" mb="0.5rem">
								Edit &amp; Remake Your Video
							</Heading>
							<Text>
								{hasSubscription ? (
									<>Need to edit or change media?</>
								) : (
									<>
										{isRemake ? (
											<>
												<strong>The first remake is free</strong> and the following remakes are
												subject to a 30% fee.
											</>
										) : (
											<>Remakes are subject to a 30% fee.</>
										)}
									</>
								)}
							</Text>
						</Box>

						<Button variant="outline" size="sm" onClick={modalActivate.onOpen}>
							Reopen Event Page
						</Button>
					</VStack>
				</Box>
			</Container>
			<FooterBase />
		</>
	)
}

export default Published
